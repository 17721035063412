import React, { useState } from 'react';
import logo from '../../img/Logo-OWs.png';

interface INavProps {}

export const Nav: React.FC<INavProps> = () => {
	const [isNav, setIsNav] = useState<boolean>(false);
	return (
		<>
			<div className='topNav'>
				<div className='container d-flex justify-content-between align-items-center'>
					<img src={logo} className='logo' alt='' />

					<div className='my-auto'>
						<ul className='nav'>
							<li className='nav-link lnk'>
								<a
									href='https://obws.com/login/'
									target='_blank'
									rel='noopener noreferrer'
								>
									Log In
								</a>
							</li>
							<li className='nav-link lnk'>
								<a
									href='https://obws.com/signup/'
									target='_blank'
									rel='noopener noreferrer'
								>
									Sign Up
								</a>
							</li>
							<li
								className='nav-link check-li'
								onClick={() => setIsNav(true)}
							>
								<input type='checkbox' id='check' />
								<label htmlFor='check' className='checkBtn'>
									<i className='fas fa-bars'></i>
								</label>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div className='bottomNav'>
				<ul className='nav justify-content-center'>
					<li className='nav-link lnkII'>
						<a
							href='https://obws.com/about-us/'
							target='_blank'
							rel='noopener noreferrer'
						>
							About Us
						</a>
					</li>
					<li className='nav-link lnkII'>
						<a
							href='https://shop.obws.com/'
							target='_blank'
							rel='noopener noreferrer'
						>
							Shop
						</a>
					</li>
					<li className='nav-link lnkII'>
						<a
							href=' https://obws.com/'
							target='_blank'
							rel='noopener noreferrer'
						>
							Features
						</a>
					</li>
					<li className='nav-link lnkII'>
						<a
							href=' https://obws.com/advertise/'
							target='_blank'
							rel='noopener noreferrer'
						>
							Advertise
						</a>
					</li>
					<li className='nav-link lnkII'>
						<a
							href='https://obws.com/search/business/'
							target='_blank'
							rel='noopener noreferrer'
						>
							Businesses
						</a>
					</li>
					<li className='nav-link lnkII'>
						<a
							href='https://obws.com'
							target='_blank'
							rel='noopener noreferrer'
						>
							On OBWS
						</a>
					</li>
					<li className='nav-link lnkII'>
						<a
							href=' https://obws.com/join/'
							target='_blank'
							rel='noopener noreferrer'
							className='link-active'
						>
							List Your Business
						</a>
					</li>
				</ul>
			</div>

			<div className='parent-responsive-nav'>
				<div
					className={`nav-responsive ${isNav ? 'd-block' : 'd-none'}`}
				>
					<ul className='nav flex-column justify-content-center'>
						<li
							className='nav-link lnkIII align-self-end close'
							onClick={() => setIsNav(false)}
						>
							<i className='fas fa-lg fa-times'></i>
						</li>
						<li className='nav-link lnkIII'>
							<a
								href='https://obws.com/abou-us/'
								target='_blank'
								rel='noopener noreferrer'
							>
								About Us
							</a>
						</li>
						<li className='nav-link lnkIII'>
							<a
								href='https://shop.obws.com/'
								target='_blank'
								rel='noopener noreferrer'
							>
								Shop
							</a>
						</li>
						<li className='nav-link lnkIII'>
							<a
								href='https://obws.com'
								target='_blank'
								rel='noopener noreferrer'
							>
								Features
							</a>
						</li>
						<li className='nav-link lnkIII'>
							<a
								href='https://obws.com/advertise/'
								target='_blank'
								rel='noopener noreferrer'
							>
								Advertise
							</a>
						</li>
						<li className='nav-link lnkIII'>
							<a
								href='https://obws.com/search/business/'
								target='_blank'
								rel='noopener noreferrer'
							>
								Businesses
							</a>
						</li>
						<li className='nav-link lnkIII'>
							<a
								href='https://obws.com'
								target='_blank'
								rel='noopener noreferrer'
							>
								On OBWS
							</a>
						</li>
						<li className='nav-link lnkIII'>
							<a
								href='https://obws.com/join/'
								target='_blank'
								rel='noopener noreferrer'
							>
								List Your Business
							</a>
						</li>
						<li className='nav-link lnkIII'>
							<a
								href='https://obws.com/login/'
								target='_blank'
								rel='noopener noreferrer'
							>
								Log In
							</a>
						</li>
						<li className='nav-link lnkIII'>
							<a
								href=' https://obws.com/signup/'
								target='_blank'
								rel='noopener noreferrer'
							>
								Sign Up
							</a>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
};
