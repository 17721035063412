import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterWrap = styled.footer`
	background: #fff;
	height: 104px;

	a {
		font-size: 14px;
		text-decoration: none;
		color: #000000;

		&:hover {
			color: #f15a24;
		}
	}
`;

export default function Footer() {
	return (
		<FooterWrap className='text-center d-flex align-items-center justify-content-center'>
			<Link to='/'>Terms &amp; Conditions</Link>
		</FooterWrap>
	);
}
