import styled from 'styled-components';
import sponsorBg from '../../img/sponsorBG.png';
import sponsorBgSmall from '../../img/sponsorBGSmall.png';

const SponsorWrapper = styled.section`
	height: 100vh;

	.bg-image {
		background-image: url(${sponsorBg});
		background-position: bottom center;
		background-repeat: no-repeat;
		background-size: 80%;
		z-index: 1;
	}
	.bg-mobile {
		background-image: none;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		z-index: -1;
	}

	.link-cover {
		text-decoration: none;
		height: 42px;
		width: 390px;
		box-shadow: 3px 3px 30px #0000004d;
		letter-spacing: 5px;
		color: #ffffff;
		font-size: 14px;
		margin-bottom: 12px;
		transition: all 0.8s ease-in-out;
	}

	.link-cover-plain {
		background: #f15a24 0% 0% no-repeat padding-box;
	}

	.link-cover-graid {
		background: transparent
			linear-gradient(
				97deg,
				#ffcf23 0%,
				#f29926 29%,
				#e4612a 62%,
				#dc3e2d 87%,
				#d9312e 100%
			)
			0% 0% no-repeat padding-box;
	}

	.link-cover2 {
		text-decoration: none;
		height: 31px;
		width: 250px;
		box-shadow: 3px 3px 30px #0000004d;
		letter-spacing: 5px;
		color: #ffffff;
		font-size: 12px;
		margin-bottom: 12px;
		transition: all 0.8s ease-in-out;
	}

	.link-cover:hover,
	.link-cover2:hover {
		transform: scale(1.1);
	}

	.link-cover2-plain {
		background: #000000 0% 0% no-repeat padding-box;
	}

	.link-cover2-white {
		background: #ffffff 0% 0% no-repeat padding-box;
		color: #000000;
	}

	.secondary-cont {
		.text-wrapper {
			width: 65%;
			p {
				font-style: italic;
				text-align: right;
				margin-bottom: 0px;
			}
			h5 {
				text-align: right;
			}
		}
	}

	@media (max-width: 3000px) and (min-width: 1800px) {
		.bg-image {
			background-size: 80vw;
			background-position: center 10%;
		}

		.inner-cover {
			display: none;
		}
	}

	@media (max-width: 2285px) and (min-width: 1300px) {
		.bg-image {
			background-size: 85vw;
			background-position: center bottom;
		}

		.secondary-cont {
			.text-wrapper {
				/* width: 75%; */
				p {
					font-style: italic;
					text-align: center;
					margin-bottom: 0px;
				}
				h5 {
					text-align: center;
				}
			}
		}

		.inner-cover {
			display: none;
		}
	}

	@media (max-width: 800px) {
		height: auto;
		.link-cover2 {
			width: 210px;
		}
		.link-cover {
			width: 311px;
		}
		.resp-padding {
			padding-top: 80px;
			padding-left: 2.5%;

			.secondary-cont {
				.text-wrapper {
					width: 100%;
					p {
						text-align: center;
					}
					h5 {
						text-align: center;
					}
				}
			}
		}

		.bg-image {
			background-position: bottom center;
			display: none;
		}

		.bg-mobile-wrap {
			height: 600px;
			.bg-mobile {
				background-image: url(${sponsorBgSmall});
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}
	}
`;

const SponsorCTA = () => {
	return (
		<SponsorWrapper className='d-flex flex-column position-relative parallax-img '>
			<div className='h-100 d-flex flex-column flex-lg-row resp-padding'>
				<div className='d-flex flex-column justify-content-center inner-cover align-items-center w-100'>
					<div className=''>
						{/* <div className='mb-3'>
							<a
								href='https://docs.google.com/forms/d/e/1FAIpQLSfPC5NZ6ZbYLtRnTG-i7nzICD3cr80sqoGRNu3f0CzGeLlybw/viewform'
								target='_blank'
								rel='noopener noreferrer'
								className='d-flex justify-content-center align-items-center link-cover link-cover-plain text-uppercase'
							>
								be a sponsor
							</a>
							<a
								href='https://docs.google.com/forms/d/e/1FAIpQLSe9Kn9EEa1ZY6-C1VK5UZQVHevyfPE0SInN6fs4H0DhzqAW5g/viewform'
								target='_blank'
								rel='noopener noreferrer'
								className='d-flex  justify-content-center align-items-center link-cover link-cover-plain text-uppercase'
							>
								attend as press
							</a>
						</div> */}
						{/* <div className='d-flex justify-content-center align-items-center d-lg-block'>
							<div className='secondary-cont'>
								<div className='text-wrapper'>
									<p className='text-uppercase'>be a</p>
									<h5 className='text-uppercase'>
										Collaborator
									</h5>
								</div>
								<div className='mb-5'>
									<a
										href='https://docs.google.com/forms/d/e/1FAIpQLSfV1BWYP60KXJ3LXT_XEgPG9ktZmH6dripn9mnjWwptkx7-vQ/viewform'
										target='_blank'
										rel='noopener noreferrer'
										className='d-flex justify-content-center align-items-center link-cover2 link-cover2-plain text-uppercase'
									>
										be a vendor
									</a>
									<a
										href='mailto:Partnerships@de1989.co'
										className='d-flex justify-content-center align-items-center link-cover2 link-cover2-plain text-uppercase'
									>
										be a host
									</a>
								</div>
							</div>
						</div> */}
					</div>
				</div>
				<div className='d-flex justify-content-center inner-cover align-items-center w-100 bg-mobile-wrap'>
					<div className='w-100 h-100 bg-mobile'></div>
				</div>
			</div>
			<div className='bg-image h-100 position-absolute w-100'></div>
		</SponsorWrapper>
	);
};

export default SponsorCTA;
