import React from 'react';
import styled from 'styled-components';
import pic1 from './../../../img/Awards_Slide_Banner_1.png';
import pic2 from './../../../img/Capital_Slide_Banner.png';

const CarouselWrap = styled.section`
	background: #000000;
	height: 568px;

	.parent-img-wrap {
		width: 70%;
	}
	.img-wrap {
		width: 45%;
		height: 433px;
		img {
			border-radius: 20px;
			object-fit: cover;
		}
	}

	.content-wrap {
		width: 25%;

		h3 {
			font-size: 20px;
			font-weight: 800;
		}

		h5 {
			font-size: 24px;
			font-weight: 400;
		}

		p {
			font-style: italic;
			font-weight: 100;
		}
	}

	@media (max-width: 850px) {
		height: auto;
		flex-direction: column-reverse !important;

		padding-top: 80px;
		.img-wrap,
		.content-wrap {
			width: 100%;
			height: 100%;
		}

		img {
			margin-bottom: 10px;
		}

		.parent-img-wrap {
			flex-direction: column-reverse !important;
			margin-bottom: 40px;
		}

		.content-wrap {
			margin-bottom: 25px;
			text-align: center;
		}
	}
`;

export const CarouselContainer = () => {
	return (
		<CarouselWrap className='px-5 d-flex flex-column flex-lg-row align-items-center justify-content-evenly'>
			<div className='parent-img-wrap d-flex flex-column flex-lg-row w-100 align-items-center justify-content-evenly'>
				<div className='img-wrap'>
					<img src={pic1} alt='' className='w-100 h-100' />
				</div>
				<div className='img-wrap'>
					<img src={pic2} alt='' className='w-100 h-100' />
				</div>
			</div>
			<div className='content-wrap'>
				<h3 className='text-uppercase text-white'>upcoming</h3>
				<h5 className='text-uppercase text-white mb-3'>obws launches</h5>
				<p className='text-uppercase text-white'>stay tuned</p>
			</div>
		</CarouselWrap>
	);
};
