import React from 'react';
import styled from 'styled-components';
import plantImgBottom from '../../../img/bottom-plant.png';
import plantImgBottomSmall from '../../../img/bottom-plantSmall.png';
import clover from '../../../img/clover.png';

interface IBannerSectionProps {}

const BannerWrap = styled.div`
	position: relative;
	.clover-parent {
		z-index: 10;
		position: absolute;
		bottom: 15rem;

		.clover {
			display: flex;
			align-items: center;
			text-decoration: none;
			cursor: pointer !important;
			p {
				color: #ffffff;
				text-transform: uppercase;
				font-size: 12px;
				margin-right: 1rem;
				margin-bottom: 0rem;
				font-weight: bold;
			}
			img {
				height: 3rem;
			}
		}
		@media (max-width: 800px) {
			bottom: 20%;
			.clover {
				flex-direction: column;
				justify-content: center;

				p {
					margin-bottom: 1.5rem;
					text-align: center;
					margin-right: 0px;
				}
			}
		}
	}
`;

export const BannerSection: React.FC<IBannerSectionProps> = () => {
	return (
		<BannerWrap>
			<section className='banner'>
				<div className='plant-img-top parallax-img'></div>

				<div className='plant-img-bottom'>
					<img src={plantImgBottom} alt='' className='plant-big' />
					<img
						src={plantImgBottomSmall}
						alt=''
						className='plant-small'
					/>
				</div>
			</section>
			<div className='d-flex justify-content-center w-100 align-items-center clover-parent'>
				<div className='clover'>
					<p>sponsored by</p>
					<a
						href='https://connect.clover.com/obws'
						target='_blank'
						rel='noopener noreferrer'
					>
						<img src={clover} alt='clover logo' />
					</a>
				</div>
			</div>
		</BannerWrap>
	);
};
