import styled from 'styled-components';

interface IJuneteenthProps {}

const JuneteethWrapper = styled.div`
	background: #000000;

	.main-cntent {
		padding-top: 74px;
		padding-bottom: 37px;
		.text-section {
			margin-right: 44px;
			h3 {
				font-size: 20px;
				text-align: center;
				letter-spacing: 6px;
				color: #ffffff;
				opacity: 1;
				font-weight: 600;
				margin-bottom: 39px;
				br {
					display: none;
				}
			}

			p {
				letter-spacing: 0.27px;
				color: #ffffff;
				opacity: 1;
				font-weight: 100;
				text-align: center;
			}
		}

		.sticker {
			width: 100%;
			background: #ffffff;
			position: relative;
			z-index: 1;
			padding: 20px 50px;

			h3 {
				margin-bottom: 0px;
				font-size: 19px;
				text-align: center;
				font-weight: 400;
				text-transform: uppercase;
			}

			h1 {
				font-size: 29px;
				text-align: center;
				text-transform: uppercase;
				margin-bottom: 20px;
				font-weight: bold;
			}

			a {
				text-decoration: none;
				padding: 10px 20px;
				text-transform: uppercase;
				color: #ffffff;
				background: #000;
				font-size: 12px;
				transition: all 0.8s ease-in-out;

				&:hover {
					transform: scale(1.1);
				}
			}
		}

		.image-section {
			height: 438.4px;
			position: relative;
			align-items: center;
			justify-content: center;
			display: flex;

			.date-section {
				margin-left: 16px;
				h3 {
					color: #ffffff;
					font-size: 55px;
					font-weight: 900;
					position: relative;
					top: 36px;
				}

				p {
					color: #ffffff;
					font-size: 34px;
					font-style: italic;
					position: relative;
					top: 7px;
					font-weight: 100;
					text-align: center;
				}
			}
		}
	}
	@media (max-width: 850px) {
		height: auto;
		.main-cntent {
			padding-top: 110px;
			padding-bottom: 0px;
			flex-direction: column !important;

			.text-section {
				margin-bottom: 44px;
				margin-right: 0px;

				h3 {
					text-align: center;
					font-size: 16px;
					br {
						display: block;
					}
					span:last-child {
						font-weight: 100;
					}
				}

				p {
					font-size: 14px;
					font-weight: 100;
					text-align: center;
					margin-bottom: 44px;
				}
			}

			.square-section {
				width: 100%;
				margin: 0px !important;
			}
			.sticker {
				a {
					/* background: #f15a24; */
				}
			}

			.image-section {
				padding-bottom: 10%;
				width: 100vw;
				height: 350px;
				.date-section {
					margin-right: 1rem;
					h3 {
						font-size: 52px;
					}
					p {
						text-align: right;
						font-size: 19px;
						top: 20px;
					}
				}
			}
		}
	}
`;

export const Juneteenth: React.FC<IJuneteenthProps> = () => {
	return (
		<JuneteethWrapper>
			<div className='container-md'>
				<div className='d-flex align-items-center main-cntent'>
					<div className='text-section col-sm-12 col-md-7'>
						<h3>
							<span>OBWS CELEBRATES</span> <br />
							<span>JUNETEENTH 2021!</span>
						</h3>
						<p>
							To celebrate Juneteenth and make it one to remember,
							Official Black Wall Street is kicking off the Summer
							with "The Warm Up" - the virtual pregame to your
							Juneteenth celebrations! Pregame with us for the
							ultimate celebration of all things Black - Black
							entrepreneurs, Black culture, Black joy!
						</p>
					</div>

					<div className='square-section col-sm-12 col-md-12 col-lg-4'>
						<div className='sticker w-100'>
							<h3 className=''>grab your</h3>
							<h1>free tickets</h1>
							<a
								href='https://www.accelevents.com/e/TheWarmUpVirtualJuneteenthPregame'
								target='_blank'
								rel='noopener noreferrer'
								className='d-flex justify-content-center align-items-center'
							>
								give me one!
							</a>
						</div>
					</div>
				</div>
			</div>
		</JuneteethWrapper>
	);
};
