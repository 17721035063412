import React from 'react';
import { BannerSection } from '../layout/banner/BannerSection';
import { CarouselContainer } from '../layout/carousel/CarouselContainer';
import Footer from '../layout/Footer';
import { Juneteenth } from '../layout/juneteenth/Juneteenth';
import LiveMusic from '../layout/LiveMusic';
import { Nav } from '../layout/Nav';
import SponsorCTA from '../layout/SponsorCTA';

interface IHome {};

export const Home: React.FC<IHome> = () => {
  return (
    <div>
      <Nav />
      <BannerSection />
      <Juneteenth />
      <LiveMusic />
      <SponsorCTA />
      <CarouselContainer />
      <Footer />
    </div>
  );
}