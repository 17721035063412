import styled from 'styled-components';

const LiveWrapper = styled.section`
	h1 {
		letter-spacing: 0.55px;
		color: #ffffff;
		opacity: 1;
		text-transform: uppercase;
	}

	.games {
		font-weight: lighter;
		font-size: 16px;
		margin-bottom: 52px;
		span {
			display: inline-block;
			height: 4px;
			width: 4px;
			background: #ffffff;
			border-radius: 10px;
			transform: translateY(-100%);
		}
	}

	h3 {
		letter-spacing: 1.95px;
		color: #ffffff;
		opacity: 1;
		font-size: 14px;
		font-weight: normal;
		text-transform: uppercase;

		span {
			display: inline-block;
			height: 4px;
			width: 4px;
			background: #ffffff;
			border-radius: 10px;
			transform: translateY(-100%);
		}
	}

	@media (min-width: 1024px) {
		.games {
			font-size: 28px;

			span {
				display: inline-block;
				height: 7px;
				width: 7px;
				background: #ffffff;
				border-radius: 10px;
				transform: translateY(-100%);
			}
		}

		h3 {
			font-size: 16px;
		}
	}
`;

const TmeWrap = styled.section`
	background: #000000;
	height: 5rem;

	h5 {
		color: #ffffff;
		margin-bottom: 0px;
		font-size: 16px;
	}

	span {
		display: block;
		margin: 0px 10px 0px 10px;
		width: 2px;
		height: 40%;
		background: #ffffff;
	}

	@media (min-width: 1024px) {
		h5 {
			font-size: 20px;
		}
	}
`;

const LiveMusic = () => {
	return (
		<>
			<LiveWrapper className='live-bg d-flex justify-content-center align-items-center '>
				<div className=''>
					<h1 className='text-center'>live music</h1>
					<h1 className='text-center'>
						JUNETEENTH-THEMED MIXOLOGY SESSION
					</h1>
					<h1 className='games text-center'>
						games <span></span> giveaways <span></span> and more!
					</h1>
					<h3 className='text-center'>
						hosted by olivia dope <span></span> live set by dj
						maverik <span></span> juneteenth address by mandy bowman
					</h3>
				</div>
			</LiveWrapper>
			<TmeWrap className='time d-flex justify-content-center align-items-center'>
				<h5>12:00 PM EST</h5>
				<span></span>
				<h5>SATURDAY, JUNE 19, 2021</h5>
			</TmeWrap>
		</>
	);
};

export default LiveMusic;
